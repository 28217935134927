/*@font-face {
    font-family: 'Futura Condensed BQ';
    src: url('futura-webfont.woff2') format('woff2'),
    url('futura-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}*/

.navLink {
    text-decoration: none;
}

.list {
    width: 250px
}
